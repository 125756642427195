<template>
    <div class="mwm-modal" @mousedown.self="closeModal()">
        <div class="mwm-modal-content" :class="modalSize">
            <slot name="alternate"></slot>

            <div class="close" @click="$emit('close')"></div>
            <header class="mwm-modal-header">
                <slot name="header"></slot>
            </header>
            <main class="mwm-modal-body">
                <slot name="body"></slot>
            </main>
            <footer class="mwm-modal-footer">
                <slot name="footer"></slot>
            </footer>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        size: {
            type: String,
            default: null,
        },
        backgroundClose: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        modalSize() {
            if (this.size) {
                return this.size
            } else {
                return 'l'
            }
        },
    },
    methods: {
        closeModal() {
            if (this.backgroundClose) {
                this.$emit('close')
            }
        },
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';

.mwm-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);

    .mwm-modal-content {
        display: flex;
        flex-direction: column;
        position: absolute;
        border-radius: 20px;
        background-color: $white;
        top: 100px;
        max-height: calc(100% - 200px);
        box-shadow: 0 0 60px rgba(0, 0, 0, 0.65);
        width: 100%;
        min-width: 400px;

        &.l {
            max-width: 1020px;
            @media screen and (max-width: 1020px) {
                border-radius: 0px;
            }
        }
        &.m {
            max-width: 800px;
            @media screen and (max-width: 800px) {
                border-radius: 0px;
            }
        }
        &.s {
            max-width: 655px;
            @media screen and (max-width: 655px) {
                border-radius: 0px;
            }
        }
        > .close {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }
    .mwm-modal-header {
        position: relative;
        padding: 15px;
        margin-bottom: 15px;
        font-weight: 300;
        font-size: 24px;
        &:empty {
            display: none;
        }
    }

    .mwm-modal-body {
        padding: 0 15px 15px 15px;
        overflow-y: auto;
    }

    .mwm-modal-footer {
        padding: 15px;
        &:empty {
            display: none;
        }
    }
}
</style>
